import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 初始化默认样式
import './assets/style/reset.scss'
import './assets/style/common.scss'
// element-ui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// 加密工具
import md5 from 'js-md5';
// 引入工具类
import utils from '@/libs/utils'
// 引入api
import api from '@/api'
// 引入封装的axios拦截器./api/plugin
import axios_plugin from './api/plugin'
// Vue.use(axios_plugin)
// 引入公共配置项
import co from './libs/commonOptions'
// 引入echarts
import * as echarts from 'echarts'
// 引入icon
import './assets/iconfont/iconfont.js'
import './assets/iconfont/iconfont.css'
import './assets/iconfont/self-icon.css'

// 引入全局组件
import tablePageComp from '@/components/tableCom/tablePageComp'
import ExportComponent from '@/components/export/listExportXlsx'
import LWtableComp from '@/components/tableCom/tableComLightweight'
Vue.component('tablePageComp', tablePageComp)
Vue.component('ExportComponent', ExportComponent)
Vue.component('LWtableComp', LWtableComp)


Vue.use(ElementUI);
Vue.prototype.$md5 = md5
Vue.prototype.utils = utils
Vue.prototype.$api = api
Vue.prototype.$axios = axios_plugin
Vue.prototype.$co = co
Vue.prototype.$echarts = echarts

Vue.config.productionTip = false

const vm = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

export default vm
