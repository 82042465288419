import { Notification } from "element-ui"; // element-ui的通知（Notification）


////////////////////////////
////// localStorage ///////
///////////////////////////

/**
 * 存储localStorage
 */
function localSetStore(name, content) {
    if (!name) return;
    if (typeof content !== "string") {
      content = JSON.stringify(content);
    }
    window.localStorage.setItem(name, content);
  }

/**
 * 获取localStorage
 */
function localGetStore(name) {
if (!name) return;
return window.localStorage.getItem(name);
}

/**
 * 删除localStorage
 */
function localRemoveStore(name) {
if (!name) return;
window.localStorage.removeItem(name);
}

/**
 * 删除所有localStorage
 */
function localClearStore() {
window.localStorage.clear();
}

////////////////////////////
///// sessionStorage //////
///////////////////////////

/**
 * 存储sessionStorage
 */
function sessionSetStore(name, content) {
    if (!name) return;
    if (typeof content !== "string") {
      content = JSON.stringify(content);
    }
    window.sessionStorage.setItem(name, content);
  }


/**
 * 获取sessionStorage
 */
function sessionGetStore(name) {
if (!name) return;
return window.sessionStorage.getItem(name);
}

/**
 * 删除sessionStorage
 */
function sessionRemoveStore(name) {
    if (!name) return;
    window.sessionStorage.removeItem(name);
}

/**
 * 删除所有sessionStorage
 */
function sessionClearStore() {
    window.sessionStorage.clear();
}

// 可关闭式通知提示，titlePara为标题，messagePara为通知内容
// 设置 duration = 0，即可不自动关闭
function notificationInfo(titlePara, messagePara, type, duration=4500) {
    let obj = {
      title: titlePara,
      message: `${messagePara}`,
      duration:duration
    };
    if (type) {
      obj.type = type;
    }
    Notification(obj);
  }
  
// 产生随机整数，包含下限值，包括上限值
function createRandomNum(lower, upper) {
  return Math.floor(Math.random() * (upper - lower + 1)) + lower;
}

// 数组每n个分一组，作为元素组成新数组
function arrayGroup(num, arr) {
  let newArr = [];
  for (let i = 0; i < arr.length; i += num) {
      newArr.push(arr.slice(i, i + num));
  }
  return newArr;
}


/**
 * 补零
 */
function zeroFill(i) {
    if (i >= 0 && i <= 9) {
      return "0" + i;
    } else {
      return i;
    }
  }

/**
 * 获取目标时间的标准格式
 * yyyy-MM-dd HH:mm:ss
 */
function getStandardTime(msec) {
    let date = new Date(msec)
    // 如果不传 自动获取当前时间
    if(!msec) {
      date = new Date()
    }
    let month = zeroFill(date.getMonth() + 1); // 月
    let day = zeroFill(date.getDate()); // 日
    let hour = zeroFill(date.getHours()); // 时
    let minute = zeroFill(date.getMinutes()); // 分
    let second = zeroFill(date.getSeconds()); // 秒
    // 当前时间
    let curTime =
      date.getFullYear() +
      "-" +
      month +
      "-" +
      day +
      " " +
      hour +
      ":" +
      minute +
      ":" +
      second;
    return curTime;
  }

// 获取前几天 或 后几天的日期,包含当前日期
function getBeforeDate(day,type) {
  let beforeDay = new Date()
  if(type == 'before') {
    beforeDay.setDate(beforeDay.getDate() - day) 
  } else {
    beforeDay.setDate(beforeDay.getDate() + day) 
  }
  let str = getStandardTime(beforeDay)
  return str
}
  
// 获取目标时间的当月第一天的日期
  function getMonthFirstDay(date) {
    date.setDate(1);
    return date;
  }

// 获取目标时间的当月最后一天的日期
function getMonthLastDay(date) {
    let currentMonth = date.getMonth();
    let nextMonth = ++currentMonth;
    let nextMonthFirstDay = new Date(date.getFullYear(), nextMonth, 1);
    let oneDay = 1000 * 60 * 60 * 24;
    return new Date(nextMonthFirstDay - oneDay);
  }
  
// 日期时间格式化1：2020-06-09T16:32:28.000+0000转为2020-06-09 16:32:28
  function dateFormat1(date) {
    let str = date.replace('T', ' ').substring(0, 19)
    return str;
  }

//时间戳转换方法    date:时间戳数字 yyyy-MM-dd HH:mm:ss
function formatDate(d) {
  var date = new Date(d);
  var YY = date.getFullYear() + '-';
  var MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
  var DD = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
  var hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
  var mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
  var ss = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
  return YY + MM + DD +" "+hh + mm + ss;
}

  //检查手机号
	function isCellPhone(val) {
    if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(val)) {
    return false;
    } else {
    return true;
    }
  }

// 防抖函数
let timeout = null
function debounce(fn, wait) {
  if(timeout !== null) clearTimeout(timeout)
  timeout = setTimeout(fn, wait)
}

// 列表时间信息处理 2021/3/1 00.00.00 => 2021/3/1
// arr 需要处理的字段名数组
// data 被处理的数据
function timeInfo(arr,data){
        
  arr.forEach(key => {
      if(data[key] != '' && data[key] != null) {
          data[key] = data[key].split(' ')[0]
      }
  })

  return data
}

// 获取当前时间
function getNow() {
  let now = new Date(),
  year = now.getFullYear(),
  month = now.getMonth() + 1,
  day = now.getDate()

  return `${year}年${month}月${day}日`
}

// 数组去重
// 无法去掉“{}”空对象
function unique(arr) {
  return Array.from(new Set(arr))
}

// 任务费用计算 from 孔
//功能：提交任务获取到本次任务预估的数量以及费用
//参数：平台ID、页数、是否需要SKU
//返回值：数据量、数据预估费用
function data_cost(platform, page, need_sku){
  let data_count = 0,
  result = 0;
  if (platform.length == 0 || page == '' || need_sku == '')
  {
      return { data_count, result };
  }
  page = Number(page);
  need_sku = Number(need_sku);

  // 平台可能为多个

  platform.forEach(item => {
    let count = 0
    switch (item){
        case '1':
            if (page <= 20){
              count = Math.floor((page - 1) * 40 + 44);
            }
            else if (page > 20 && page <= 30){
              count = Math.floor(((page - 1) * 40 + 44)/1.5);
            }
            else if (page > 30 && page <= 50){
              count = Math.floor(((page - 1) * 40 + 44)/2.1);
            }
            else if (page > 50){
              count = Math.floor(((page - 1) * 40 + 44)/2.3);
            }
            count = Math.min(count , 1760);
            if (need_sku != 0){
              count = Math.floor(Number((Math.random()*(1.8-1.5) + 1.5).toFixed(1) * count));
            }
            break;
        case '5':
        case '21':
          count = page * 10 * 20;
        case '3':
        case '6':
        case '10':
        case '12':
          if (page <= 20){
              count = Math.floor(page * 60);
            }
            else if (page > 20 && page <= 30){
              count = Math.floor((page * 60)/1.5);
            }
            else if (page > 30 && page <= 50){
              count = Math.floor((page * 60)/2.1);
            }
            else if (page > 50){
              count = Math.floor((page * 60)/2.3);
            }
            count = Math.min(count , 3620);
            if (need_sku != 0){
              count = Math.floor(Number((Math.random()*(1.8-1.5) + 1.5).toFixed(1) * count));
            }
            break;
        case '4':
          count = Math.floor(Math.random() * (300 - 280) ) + 280;
        default:
            break;
    }
    data_count += count
  })
  result = Math.ceil(data_count * 0.05);
  return { data_count, result };
}

// 商品链接处理，由于已下架商品取不到商品链接，需自行进行商品链接设置
// params: 全部链接信息
// platform String; "淘宝"
// goods_id 商品id
// shop_id 店铺id
function setGoodsUrl(params) {
  if(!params.platform || !params.goods_id) return
  let url = ''
  switch(params.platform) {
    case '淘宝':
        url = `https://item.taobao.com/item.htm?id=${params.goods_id}`
        break;
    case '天猫':
        url = `https://detail.tmall.com/item.htm?id=${params.goods_id}`
        break;
    case '京东':
        url = `https://item.jd.com/${params.goods_id}.html`
        break;
    case '拼多多':
        url = `https://mobile.yangkeduo.com/goods2.html?goods_id=${params.goods_id}`
        break;
    case '1688':
        url = `https://detail.1688.com/offer/${params.goods_id}.html`
        break;
    case '当当':
        url = `http://product.dangdang.com/${params.goods_id}.html`
        break;
    case '抖音':
        url = `https://haohuo.jinritemai.com/views/product/item2?id=${params.goods_id}`
        break;
    case '闲鱼':
        url = `http://2.taobao.com/item.htm?id=${params.goods_id}`
        break;
    case '小红书':
        url = `https://www.xiaohongshu.com/goods-detail/${params.goods_id}`
        break;
    case '微店':
        url = `https://weidian.com/item.html?itemID=${params.goods_id}`
        break;
    case '苏宁易购':
        url = `https://product.suning.com/${params.shop_id}/${params.goods_id}.html`
        break;
  }
  return url;
}


let utils = {
    localSetStore,
    localGetStore,
    localRemoveStore,
    localClearStore,
    sessionSetStore,
    sessionGetStore,
    sessionRemoveStore,
    sessionClearStore,
    notificationInfo,
    createRandomNum,
    arrayGroup,
    getStandardTime,
    getMonthLastDay,
    getMonthFirstDay,
    dateFormat1,
    isCellPhone,
    debounce,
    formatDate,
    timeInfo,
    getBeforeDate,
    unique,
    data_cost,
    getNow,
    setGoodsUrl,
};
export default utils;