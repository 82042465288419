// 日期配置
var dateOptions = [];
for (let i = 1; i < 32; i++) {
  dateOptions.push({
    label: i + "号",
    value: String(i),
  });
}
// 时间点配置
var timeOptions = [];
for (let i = 0; i < 24; i++) {
  timeOptions.push({
    label: (i < 10 ? "0" + i : i) + ":00",
    value: String(i),
  });
}

export default {
  // 月份配置
  monthOptions: [
    {
      label: "一月",
      value: "1",
    },
    {
      label: "二月",
      value: "2",
    },
    {
      label: "三月",
      value: "3",
    },
    {
      label: "四月",
      value: "4",
    },
    {
      label: "五月",
      value: "5",
    },
    {
      label: "六月",
      value: "6",
    },
    {
      label: "七月",
      value: "7",
    },
    {
      label: "八月",
      value: "8",
    },
    {
      label: "九月",
      value: "9",
    },
    {
      label: "十月",
      value: "10",
    },
    {
      label: "十一月",
      value: "11",
    },
    {
      label: "十二月",
      value: "12",
    },
  ],
  dateOptions,
  // 周配置
  weeksOptions: [
    {
      label: "周一",
      value: "1",
    },
    {
      label: "周二",
      value: "2",
    },
    {
      label: "周三",
      value: "3",
    },
    {
      label: "周四",
      value: "4",
    },
    {
      label: "周五",
      value: "5",
    },
    {
      label: "周六",
      value: "6",
    },
    {
      label: "周日",
      value: "7",
    },
  ],
  // 时间点配置
  timeOptions,
  // 平台配置项
  pltOptions: [
    {
      label: "淘宝",
      value: "1",
      py: "taobao",
      icon: "#icon-taobao2",
    },
    {
      label: "天猫",
      value: "2",
      py: "tianmao",
      noEdit: true, // 不放入任务编辑页面
    },
    {
      label: "京东",
      value: "3",
      py: "jingdong",
      icon: "#icon-jd",
    },
    {
      label: "拼多多",
      value: "4",
      py: "pinduoduo",
      icon: "#icon-pinduoduo",
    },
    {
      label: "闲鱼",
      value: "5",
      py: "xianyu",
      icon: "#icon-a-fish_logo1",
    },
    {
      label: "当当",
      value: "6",
      py: "dangdang",
      icon: "#icon-other",
    },
    {
      label: "1688",
      value: "10",
      py: "1688",
      icon: "#icon-a-1688-fill",
    },
    {
      label: "小红书",
      value: "11",
      py: "xiaohongshu",
      icon: "#icon-xiaohongshu",
    },
    {
      label: "苏宁易购",
      value: "12",
      py: "suning",
      icon: "#icon-suningyigou-copy",
    },
    {
      label: "唯品会",
      value: "20",
      py: "weipinhui",
      icon: "#icon-weipinhui",
    },
    {
      label: "微店",
      value: "21",
      py: "weidian",
      icon: "#icon-dianshangAPP",
    },
    {
      label: "抖音",
      value: "22",
      py: "douyin",
      icon: "#icon-douyin",
    },
    {
      label: "快手",
      value: "23",
      py: "kuaishou",
      icon: "#icon-kuaishou",
    },
  ],
  // 操作频次配置
  operationFOption: [
    { value: "1次/月" },
    { value: "2次/月" },
    { value: "3次/月" },
    { value: "1次/周" },
    { value: "2次/周" },
    { value: "1次/日" },
    { value: "其他：" },
  ],
  // 任务优先级配置
  taskLevelOptions: [
    {
      label: "低",
      value: "0",
    },
    {
      label: "中",
      value: "1",
    },
    {
      label: "高",
      value: "2",
    },
    {
      label: "极高",
      value: "3",
    },
    {
      label: "无与伦比高",
      value: "4",
    },
  ],
  // 投诉结果
  complaintResOptions: [
    {
      label: "投诉成功",
      value: "投诉成功",
    },
    {
      label: "投诉失败",
      value: "投诉失败",
    },
    {
      label: "受理中",
      value: "受理中",
    },
  ],

  // 任务列表和数据列表导出窗口的默认选中字段
  defaultSelectedArr: [
    {
      chinese: "平台",
      english: "platform",
    },
    {
      chinese: "商品编号",
      english: "goods_id",
    },
    {
      chinese: "商品名称",
      english: "goods_title",
    },
    {
      chinese: "商品链接",
      english: "goods_url",
    },
    {
      chinese: "店铺名称",
      english: "shop_name",
    },
    {
      chinese: "掌柜昵称",
      english: "shopkeeper_nick",
    },
    {
      chinese: "SKU名称",
      english: "sku_att_class",
    },
    {
      chinese: "SKU编号",
      english: "sku_id",
    },
    {
      chinese: "SKU页面价",
      // english: "sku_now_price",
      english: "sku_old_price",
    },
    {
      chinese: "SKU预估到手价",
      english: "sku_hand_price",
    },
    {
      chinese: "销量",
      english: "month_sale_num",
    },
    {
      chinese: "发货地",
      english: "place_of_delivery",
    },
    {
      chinese: "更新时间",
      english: "update_at",
    },
  ],
};
