e// 选择字段并导出表格组件
<template>
  <!-- 导出数据 -->
  <el-dialog
    class="exportData_dialog"
    title="导出数据"
    custom-class="popup_style"
    :visible.sync="exportDataDialog"
    center
    width="800px">
    <div class="form_main clearfix">
      <div class="export_dialog">
        <el-select
          v-model="exportType"
          size="mini"
          @change="exportTypeChange"
          v-if="exportTypeSwitch">
          <el-option label="SKU版" value="SKU"></el-option>
          <el-option label="SPU版" value="SPU"></el-option>
        </el-select>
        <div class="label_export">导出字段：</div>
        <div class="checkbox_export">
          <el-checkbox
            :indeterminate="qxShowState"
            v-model="qxCheckState"
            @change="handleCheckAllChange"
            >全选</el-checkbox
          >
          <el-checkbox-group
            v-model="exportDataSelect"
            @change="exportDataSelectChange">
            <el-checkbox
              v-for="(item, index) in keyRenderArr"
              :key="index"
              :label="index"
              >{{ item.chinese }}</el-checkbox
            >
          </el-checkbox-group>
        </div>
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="exportDataConfirm" size="small"
        >确 定</el-button
      >
      <el-button size="small" @click="exportDataDialog = false"
        >取 消</el-button
      >
    </div>
  </el-dialog>
</template>
<script>
// 引入工具类
import utils from "@/libs/utils";
// 引入api
import api from "@/api/index";
export default {
  name: "listExportXlsx",
  props: {
    // 导出数据可选择的所有字段
    exportDataAll: {
      // type: Object,
      default: () => {
        return [];
      },
    },
    // 点击导出标记
    exportClickTag: {
      type: Boolean,
    },
    // API名称
    apiName: {
      type: String,
    },
    // 默认选中字段
    defaultSelected: {
      type: Array,
      default: () => null,
    },
    // 导出类型切换功能开关
    exportTypeSwitch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      exportDataDialog: false, // 对话框状态
      exportDataSelect: [], // 导出数据选择的字段
      qxCheckState: true, // 全选按钮的状态
      qxShowState: true, // 全选按钮的显示状态
      exportType: "SKU",
    };
  },
  watch: {
    // 点击导出标记
    exportClickTag() {
      // 显示对话框
      this.showDialog();
    },
  },
  computed: {
    keyRenderArr() {
      if (this.exportTypeSwitch) {
        return this.exportDataAll[this.exportType];
      } else {
        return this.exportDataAll;
      }
    },
  },
  created() {},
  methods: {
    /*
     * 操作函数
     */
    // 显示对话框
    showDialog() {
      this.exportDataSelect = [];
      // 导出数据对话框---显示
      this.exportDataDialog = true;
      this.exportTypeChange();
    },
    exportTypeChange() {
      if (this.exportType == "SKU" && this.defaultSelected) {
        this.qxCheckState = false;
        this.qxShowState = false;
        this.keyRenderArr.forEach((item, index) => {
          if (this.defaultSelected.some((s) => s.english == item.english)) {
            this.exportDataSelect.push(index);
          }
        });
      } else {
        this.qxCheckState = true;
        this.qxShowState = true;
        // 导出数据选择的字段
        let allArr = [];
        for (let i = 0; i < this.keyRenderArr.length; i++) {
          allArr.push(i);
        }
        // 导出数据选择的字段---默认全选
        this.exportDataSelect = allArr;
      }
    },
    // 导出数据选择的字段发生变化
    exportDataSelectChange(value) {
      let checkedCount = value.length;
      // 全选按钮的状态---更新
      this.qxCheckState = checkedCount === this.keyRenderArr.length; // 是否全选
      // 全选按钮的显示状态---更新
      this.qxShowState =
        checkedCount > 0 && checkedCount == this.keyRenderArr.length;
    },
    // 全选按钮发生变化
    handleCheckAllChange(val) {
      let allArr = [];
      for (let i = 0; i < this.keyRenderArr.length; i++) {
        allArr.push(i);
      }
      // 导出数据选择的字段---更新
      this.exportDataSelect = val ? allArr : [];
      // 全选按钮的显示状态---更新
      this.qxShowState = false;
    },
    // 点击 导出数据对话框 的确定
    exportDataConfirm() {
      if (this.exportDataSelect.length < 1) {
        utils.notificationInfo("提示", "请选择要导出的字段", "warning");
        return;
      }
      // 通知父组件
      this.$emit(
        "getApiParams",
        function (apiParams) {
          let paramObj = {
            chinese: "", // 格式：'品牌名称,商品标题'
            english: "", // 格式：'brand_name,goods_title'
            ...apiParams,
          };
          if (this.exportTypeSwitch) {
            paramObj.outListType = this.exportType == "SKU" ? 0 : 1;
          }
          for (let i = 0; i < this.exportDataSelect.length; i++) {
            paramObj.chinese +=
              this.keyRenderArr[this.exportDataSelect[i]].chinese + ",";
            paramObj.english +=
              this.keyRenderArr[this.exportDataSelect[i]].english + ",";
          }
          // console.log(paramObj)
          // 导出数据
          this.dataExportFunc(paramObj);
        }.bind(this)
      );
    },
    /*
     * api相关
     */
    // 导出数据
    dataExportFunc(paramObj) {
      const loading = this.$loading({
        lock: true,
        text: "正在导出数据",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      api[this.apiName](paramObj)
        .then(
          function (response) {
            window.location.href = response.data.url;
            // 导出数据对话框---隐藏
            this.exportDataDialog = false;
            loading.close();
          }.bind(this)
        )
        .catch((error) => {
          console.log(error);
          // 导出数据对话框---隐藏
          this.exportDataDialog = false;
          loading.close();
          utils.notificationInfo("错误提示", "导出失败", "error");
        });
    },
    /*
     * 功能函数
     */
  },
};
</script>
<style lang="scss" scoped></style>
