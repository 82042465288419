// 分页组件
<template>
  <el-pagination
    :background="backg"
    :current-page="currentPage"
    @size-change="pageSizeChange"
    @current-change="currentPageChange"
    :page-sizes="pageSizeOption"
    :page-size="pageSize"
    :layout="layoutConfig"
    :total="total">
  </el-pagination>
</template>
<script>
// 引入工具类
import utils from "@/libs/utils";
export default {
  name: "paginationComp",
  props: {
    backg: {
      type: Boolean,
      default: true
    },
    // 页面条数配置项
    pageSizeOption: {
      type: Array,
      default() {
        return [15, 20, 25, 30]
      }
    },
    // 当前页数 
    currentPageFzj: {
      type: Number,
      default: 1
    },
    // 组件布局
    layoutConfig: {
      type: String,
      default: 'prev, pager, next, jumper, sizes, total'
    },
    // 每页条数
    pageSizeFzj: {
      type: Number,
      default: 15
    },
    // 总条数
    totalFzj: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      // 当前页数
      currentPage: this.currentPageFzj,
      // 每页条数
      pageSize: this.pageSizeFzj,
      // 总条数
      total: this.totalFzj,
    };
  },
  watch: {
    // 当前页数
    currentPageFzj (value) {
      this.currentPage = value
      // console.log('页码组件',value)

    },
    // 总条数
    totalFzj (value) {
      this.total = value
      // console.log(this.total,this.pageSizeFzj,this.pageSize)
      
    },
  },
  created() {},
  methods: {
    /*
     * 操作函数
     */
    // 当前页数发生变化
    currentPageChange (value) {
      this.currentPage = value
      // 分页变化处理函数
      this.pageParamChangeFunc()
    },
    // 每页条数发生变化
    pageSizeChange (value) {
      this.currentPage = 1
      this.pageSize = value
      // 分页变化处理函数
      this.pageParamChangeFunc()
    },
    /*
     * api相关
     */

    /*
     * 功能函数
     */
    // 分页变化处理函数
    pageParamChangeFunc() {
      // 通知父组件分页参数变化
      this.$emit('pageParamChange', {
        currentPage: this.currentPage,
        pageSize: this.pageSize
      })
    }
  }
};
</script>
<style lang="scss">
  .el-pagination.is-background .el-pager li:not(.disabled) {
    background-color: #F4F4F5;   // 进行修改未选中背景和字体
    color: #606266;
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #409EFF;   // 进行修改选中项背景和字体
    color: #fff;
  }
</style>