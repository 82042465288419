// 表格分页组件
<template>
  <div class="table-contentBox">
    <!-- 批量操作区域 只有一个操作按钮-->
    <div class="operateBox" ref="operateBox" v-if="(multipleSelection.length > 0 || selectConfig.showNow) && selectConfig.workInTable">
      <div class="operate_main">
        <el-button type="primary" size="mini" @click="operateHandle">{{selectConfig.handle || '操作'}}</el-button>
      </div>
    </div>
    <!-- 批量操作区域2  共xxx项，已选择 x 项 勾选全部/ 取消勾选 -->
    <div class="selection" v-if="selectConfig.operationVis">
      共<span> {{totalCount}} </span>项，当前页共<span>{{dataList.length}}</span>项，已选择<span> {{selectedNum}} </span>项
      <el-button size="mini" type="text" @click="selectAll">勾选全部</el-button>
      /
      <el-button @click="clearSelected" size="mini" type="text" style="margin-left: 0;">取消勾选</el-button>
      /
      <el-button @click="reverseSelect" size="mini" type="text" style="margin-left: 0;">反选</el-button>
    </div>
    <div class="tableBox commonTable">
      <el-table
        size="mini"
        border
        v-loading="loading"
        element-loading-text="获取数据中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.7)"
        :max-height="tableHeight || tableMaxHeight"
        header-row-class-name="headerStyle"
        :row-class-name="tableRowClassName"
        :tooltip-effect="tooltipEffect"
        ref="tableDom"
        @selection-change="handleSelectionChange" 
        :data="dataList"
        :row-style="rowStyle"
        :row-key="(row) => row.id"
      >
        <!-- 表格没内容时，不设置默认高度 -->
        <!-- :height="tableHeight || tableMaxHeight" -->

        <!-- 选择框 -->
        <el-table-column
          v-if="selectConfig && selectConfig.visible"
          :width="selectConfig.width ? selectConfig.width : '51'"
          :align="selectConfig.align ? selectConfig.align : 'center'"
          type="selection"
          :fixed="selectConfig.isFixed"
          reserve-selection
        >
        </el-table-column>
        <!-- 序号 -->
        <el-table-column
          v-if="orderNumConfig"
          :width="orderNumConfig.width ? orderNumConfig.width : '81'"
          :align="orderNumConfig.align ? orderNumConfig.align : 'center'"
          label="序号"
          :fixed="orderNumConfig.fixed ? orderNumConfig.fixed : false"
        >
          <template slot-scope="scope">
            <!-- 带分页 -->
            <div v-if="totalCount || totalCount == 0">{{(paginationObj.currentPage - 1) * paginationObj.pageSize + (scope.$index + 1)}}</div>
            <!-- 不带分页 -->
            <div v-else>{{scope.$index + 1}}</div>
          </template>
        </el-table-column>
        <!-- 通用及渲染类 -->
        <template v-for="(item, index) in columnsList">
          <!-- 无插槽的列 -->
          <el-table-column
            v-if="!item.slotName"
            :key="index"
            :width="item.width ? item.width : ''"
            :min-width="item.minWidth ? item.minWidth : ''"
            :align="item.align ? item.align : 'center'"
            :label="item.label"
            :prop="item.prop"
            :show-overflow-tooltip="item.tooltip"
          >
            <template slot-scope="scope">
              <!-- 使用render函数的 -->
              <ex-slot
                v-if="item.render"
                :render="item.render"
                :row="scope.row"
                :index="scope.$index"
                :column="item"
              />
              <!-- 不使用render函数的 -->
              <span v-else>{{item.formatter ? item.formatter(scope.row[item.prop], scope.row, item, scope.$index) : scope.row[item.prop]}}</span>
            </template>
          </el-table-column>
          <!-- 有插槽的列 -->
          <slot v-else :name="item.slotName" />
        </template>
        <!-- 操作栏 -->
        <slot name="operate" />
      </el-table>
    </div>
    
    <!-- 分页区域 -->
    <div v-if="totalCount || totalCount == 0" ref="pageBox" class="pageBox">
      <paginationComp 
      @pageParamChange="pageParamChangeFunc" 
      :currentPageFzj="paginationObj.currentPage" 
      :pageSizeFzj="paginationObj.pageSize" 
      :pageSizeOption="paginationObj.pageSizeOption"
      :totalFzj="paginationObj.total"
      :layoutConfig="pageSizeConfig.layoutConfig">
      </paginationComp>
    </div>
  </div>    
</template>
<script>
// 引入工具类
// import utils from "@/libs/utils";
// 引入api
import api from '@/api/index'
// 引入分页组件
import paginationComp from '@/components/tableCom/paginationComp'
// 自定义内容的组件
var exSlot = {
  functional: true,
  props: {
    row: Object,
    render: Function,
    index: Number,
    column: {
      type: Object,
      default: null
    },
  },
  render: (h, data) => {
    const params = {
      row: data.props.row,
      index: data.props.index
    };
    if (data.props.column) params.column = data.props.column;
    return data.props.render(h, params);
  }
};

export default {
  name: "tableComp",
  components: {
    exSlot,
    paginationComp
  },
  props: {
    // 表格的父元素容器高度，如果有，就使用新的高度计算方式
    containerHeight: {
      type: Number,
      default: 0
    },
    // table的行高
    rowHeight: {
      type: Number,
      default: 0,
    },
    // table的高度
    tableHeight: {
      type: Number,
      default: 0, // if(0)时，0表示false，走else
    },
    // tooltip风格
    tooltipEffect: {
      type: String,
      default: 'dark', // 值：dark/light
    },
    // 选择框配置项
    selectConfig: {
      type: Object,
      default: () => {
        return {
          showNow: false,
          visible: false,
          isFixed: false,
          operationVis: false
        }
      }
      // 示例
      // {
      //   handle:'批量删除', // 操作按钮名字
      //   width: '', // 选项列宽度
      //   visible: hierarchy == 0, // 非最高权限不显示，是否展示选择框
      //   apiConfig: selectConfigFunc() // 绑定操作方法
      //   showNow: true, // 是否立即显示操作区域，否则只在选择后显示操作区域
      //   workInTable: true, // 是否在表格中操作数据，是---显示操作区域 否则---将已选择的数据返回给父组件
      //   isFixed: true, // 是否固定在左侧
      //   operationVis: true, // 是否显示批量操作区域2
      // }
    },
    // 序号配置项
    orderNumConfig: {
      type: Object,
    },
    // 列的数据
    columnsList: {
      type: Array,
    },
    // 表格数据
    dataList: {
      type: Array,
      default: () => {
        return [
          // {
          //   name: '小王',
          //   age: 17,
          //   number: 1,
          //   imgUrl: 'https://t00img.yangkeduo.com/goods/images/2020-04-17/b91b4d91-30b7-4172-8f6a-1110ebd6725d.jpg'
          // },
          // {
          //   name: '小李',
          //   age: 22,
          //   number: 2,
          //   imgUrl: 'https://t00img.yangkeduo.com/goods/images/2020-06-01/e25ed111-1f25-4ad5-ace7-533cd850fc5a.jpg'
          // }
        ]
      }
    },
    // 获取数据配置项
    getDataConfig: {
      type: Object
    },
    // 主动更新数据标记
    updateDataTag: {
      type: Boolean
    },
    updateDataSignObj: {
      type: Object,
      // 示例
      // {
      //   updateDataTag: false,
      //   resetPageNum: false // 是否需要重置页码 更新数据时重置页码为1，表格滚动到顶部
      // }
      default: () => {
        return {
          resetPageNum: true
        }
      }
    },
    // 数据总条数
    totalCount: {
      type: Number
    },
    // 当前页码---若是想要默认跳转到上次浏览到的页面，需要从父组件传递这个参数
    currentPage: {
      type: Number,
      default: 1
    },
    // 工单列表有tab栏，表格高度需要减去此高度，且需给横向滚动条预留出高度 10px
    tabHeight: {
      type: Number,
    },
    // 分页组件配置
    pageSizeConfig: {
      type: Object,
      default: () => {
        return {
          pageSizeOption:[15, 20, 25, 30],
          pageSize: 15
        }
      }
    },
  },
  data() {
    return {
      // 表格中选择的所有项的数据
      multipleSelection: [], 
      selectedNum: 0, // 已选择的数量
      isSelectAll: false,// 是否选中全部信息
      // 表格最大高度
      tableMaxHeight: null,
      // 分页组件配置对象
      paginationObj: {
        // 当前页数
        currentPage: this.currentPage,
        // 每页条数
        // pageSize: 15,
        pageSize: this.pageSizeConfig.pageSize,
        // 总条数
        total: 0,
        // 每页展示条数配置
        pageSizeOption: this.pageSizeConfig.pageSizeOption,
      },
      // 加载中状态
      loading: false
    };
  },
  watch: {
    // 如果不需要页码和滚动位置在数据刷新时不重置，就还使用upDateDataTag的逻辑
    // 如果需要，就设置this.updateDataSignObj
    // 主动更新数据标记
    updateDataTag: {
      handler () {
        if (this.totalCount || this.totalCount == 0) {
          // 分页组件配置对象---更新
          this.paginationObj.currentPage = 1 //每次更新表格内容时，默认显示第一页
        }

        // 调用获取数据方法
        this.getDataCall()
      },
      immediate: true
    },
    // 主动更新数据标记配置
    'updateDataSignObj.updateDataTag': {
      handler(val) {
        // 需要重置页码，进行数据更新,并使表格内容回到顶部
        if(this.updateDataSignObj.resetPageNum) {
          if (this.totalCount || this.totalCount == 0) {
            // 分页组件配置对象---更新
            this.paginationObj.currentPage = 1 //每次更新表格内容时，默认显示第一页
          }
        }
        this.getDataCall()
        this.$nextTick(()=> {
          // 列表每次更新，自动清除已选项
          this.clearSelected()
        })
      },
      immediate: true,
    },
    // 数据总条数
    totalCount (value) {
      // 分页组件配置对象---更新
      this.paginationObj.total = value
    },
    // 批量选择数据，如果selectConfig.workInTable = false,将所选内容返回给父组件
    multipleSelection(val) {
      if(!this.selectConfig.workInTable) {
        if(this.isSelectAll) {
          this.$emit('multipleList', 'selectAll')
        } else {
          this.$emit('multipleList', val)
        }
      }
    }
  },
  created() {
    // 每次加载
  },
  mounted() {
    // 计算表格高度
    this.computeTableHeight()
    // 屏幕大小发生变化
    window.addEventListener(
      "resize",
      function () {
        if (this.resizeTimer) clearTimeout(this.resizeTimer);
        // 屏幕大小变化函数节流对象
        this.resizeTimer = setTimeout(
          function () {
            // 计算表格高度
            this.computeTableHeight()
          }.bind(this),
          50
        );
      }.bind(this)
    );
  },
  methods: {
    /*
     * 操作函数
     */
    // 表格行高
    rowStyle() {
      if (this.rowHeight) {
        return `height: ${this.rowHeight}px;`
      } else {
        return ''
      }
    },
    // 分页变化处理函数
    pageParamChangeFunc (value) {
      // 分页组件配置对象---更新
      this.paginationObj.currentPage = value.currentPage
      this.paginationObj.pageSize = value.pageSize
      // 调用获取数据方法
      this.getDataCall()
    },

    // 批量选择相关
    // 表格的选择项发生变化
    handleSelectionChange (val) {

      // console.log(val)
      // console.log(this.selectedNum)
      // 表格中选择的所有项的数据---更新
      this.multipleSelection = val
      if(this.selectedNum == this.totalCount) {
        this.isSelectAll = false
      }

      if(this.isSelectAll) {
        this.selectedNum = this.totalCount
      } else {
        this.selectedNum = val.length
      }
    },
    selectAll() {
      // 勾选全部，需要使当前页中的信息都处于选中状态，已选择数量等于 totalCount
      // 若已经全选 禁用
      if(this.isSelectAll) {
        return
      }
      // 如果在已选择本页全部数据之后，再去点击勾选全部，会导致所有已选中状态改为未选中
      // 所以只在未全部选择本页数据时，再去触发全部选择事件
      if(this.selectedNum != this.dataList.length) {
        this.$refs.tableDom.toggleAllSelection()
      } else {
        this.selectedNum = this.totalCount
        this.$emit('multipleList', 'selectAll')
      }
      this.isSelectAll = true

    },
    clearSelected() {
      this.$refs.tableDom.clearSelection();
      this.selectedNum = 0
      this.isSelectAll = false
    },
    // 反选
    reverseSelect() {
      this.dataList.forEach(row => {
        this.$refs.tableDom.toggleRowSelection(row)
      })
    },

    /*
     * api相关
     */
    // 调用获取数据方法
    getDataCall () {
      if (this.loading) {
        return
      }
      // 加载中状态---开启
      this.loading = true
      // 重置批量选择内容
      if(this.selectConfig.operationVis) {
        // 不清空已选项 2022-7-7
        // this.selectedNum = 0 
        // this.multipleSelection = []
        // this.isSelectAll = false
      }
      
      // console.log(this.getDataConfig)
      let getDataConfigObj = this.getDataConfig //通过组件传值传来的查询条件对象
      let that = this

      // 处理参数
      let paramObj = getDataConfigObj.paramObj
      // console.log(this.totalCount)
      //如果有分页时，把当前页码和一页的条数加入到请求参数中
      if (this.totalCount || this.totalCount == 0) {
        paramObj.pageNow = this.paginationObj.currentPage,
        paramObj.pageSize = this.paginationObj.pageSize
      }
      // 请求后台api
      api[getDataConfigObj.apiName](paramObj)
        .then(function (response) {
          // 成功回调
          getDataConfigObj.success(response, function () {
            if(that.updateDataSignObj.resetPageNum) {
              // 表格滚动条回到顶部
              that.$nextTick(function () {
                that.$refs.tableDom.bodyWrapper.scrollTop = 0;
              });
            } else {
              // 如果resetPageNum为false，在请求一次数据后，将它改回默认值true
              that.$emit('setResetPageNum', true)
            }
            // 加载中状态---关闭
            that.loading = false

            // 设置展开列时，通过ref获取不到表格，通过事件传给父组件
            that.$emit('refInfo',that.$refs.tableDom)
          })
        })
        .catch(error => {
          // 加载中状态---关闭
          that.loading = false
          // 失败回调
          getDataConfigObj.failed(error, function () {
            console.log(error)
          })
          // getDataConfigObj.failed(error)
        })
    },
    // 批量操作按钮绑定事件
    operateHandle() {
      if(this.selectConfig.apiConfig) {
        this.$confirm('是否确定继续此操作?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {

          let apiConfig = this.selectConfig.apiConfig
          // 获取api参数
          let param = apiConfig.paramFunc(this.multipleSelection)

          api[apiConfig.apiName](param)
          .then(res => {
            apiConfig.success(res, function() {})
          })
          .catch(error => {
            apiConfig.failed(error, function() {})
          })
        })

      } else {
        // 批量操作前为获取到父组件传来的api信息，禁止操作
        return
      }
    },
    /*
     * 功能函数
     */
    // 表格自定义斑马线
    tableRowClassName ({ rowIndex }) {
      if (rowIndex % 2 !== 0) {
        return 'trStyle'
      }
      return ''
    },
    // 计算表格高度
    computeTableHeight () {
      if (this.tableHeight) return // if(0)时，0表示false，走else
      let bodyHeight = document.documentElement.clientHeight || document.body.clientHeight
      // 顶部导航高度
      // let headerH = this.$store.state.headerHeight
      let headerH = 50

      // 标题高度
      let titleH = 0
      const titleDom = document.getElementsByClassName('module_title')
      if (titleDom.length) {
        titleH = titleDom[0].clientHeight
      }
      // 查询区域高度
      let queryBoxH = 0
      const queryBoxDom = document.getElementsByClassName('queryBox')
      if (queryBoxDom.length) {
        queryBoxH = queryBoxDom[0].clientHeight
        if(queryBoxH == 0) {
          queryBoxH = 69 // 如果有查询条件，且未获取到区域高度。默认给个值
        }
      }
      // 操作区域高度
      let operateBoxH = 0
      const operateBoxDom = document.getElementsByClassName('operateBox')
      if (operateBoxDom.length) {
        operateBoxH = operateBoxDom[0].clientHeight
      }
      // 批量选择区域
      let selectionH = 0
      const selectionDom = document.getElementsByClassName('selection')
      if(selectionDom.length) {
        selectionH = selectionDom[0].clientHeight
      }

      // 分页高度
      let pageBoxH = 0
      const pageBoxDom = document.getElementsByClassName('pageBox')
      if (pageBoxDom.length) {
        pageBoxH = pageBoxDom[0].clientHeight
        if(pageBoxH == 0) {
          pageBoxH = 60 // 如果有分页，且未获取到分页高度。默认给60
        }
      }
       
      // 外边距
      let marginHeight = 15 + 15
      // 内边距
      let paddingHeight = 20 + 10
      // 预留边距
      // let hopeMargin = 15
      let hopeMargin = 0
      // 其它高度
      let otherH = marginHeight + paddingHeight + hopeMargin
      // tab选项栏高度
      let tabH = 0
      if(this.tabHeight) {
        tabH = this.tabHeight + 10 // 10 是预留的横向滚动条高度
      }

      // console.log(headerH)
      // console.log(titleH)
      // console.log('query',queryBoxH)
      // console.log('operateBoxH',operateBoxH)
      // console.log('pageBoxH',pageBoxH)
      // console.log('otherH',otherH)
      // console.log('bodyHeight',bodyHeight)
      // 表格最大高度---更新
      this.tableMaxHeight = bodyHeight - (headerH + titleH + queryBoxH + operateBoxH + pageBoxH + otherH + tabH + selectionH)
      // 如果有表格父元素高度
      if(this.containerHeight) {
        this.tableMaxHeight = this.containerHeight - (otherH + pageBoxH + tabH)
      }
      // 设置表格最小高度
      if(this.tableMaxHeight < 400) {
        this.tableMaxHeight = 400
      }
      // console.log('表格最大高度',this.tableMaxHeight)
    }
  }
};
</script>
<style lang="scss" scoped>
</style>
<style lang="scss">
  .table-contentBox {
    position: relative;
    .tableBox{
      // 滚动条自定义
      // 滚动条容器
      .el-table__body-wrapper::-webkit-scrollbar {
        width: 10px; /*滚动条宽度*/
        height: 10px; /*滚动条高度*/
      }
      // 滑块样式
      .el-table__body-wrapper::-webkit-scrollbar-thumb {
        border-radius: 10px; /*滚动条的圆角*/
        background: rgba(135, 135, 135, 0.4); /*滚动条的背景颜色*/
      }
      // 轨道样式
      .el-table__body-wrapper::-webkit-scrollbar-track {
        background: rgba(135, 135, 135, 0.1); /*滚动条的背景颜色*/
      }
      // 隐藏表格展开列的默认图标
      .el-table__expand-column .cell {
            display: none;
      }
    }

    // 操作区域
    .operateBox {
      // position: absolute;
      // bottom: 20px;

      margin-bottom: 10px;
    }
    // 批量操作区域
    .selection {
      height: 30px;
      border: 1px solid #108cee;
      margin-bottom: 8px;
      background-color: #e6f7ff;
      border-radius: 5px;
      line-height: 30px;
      padding-left: 20px;
      font-size: 14px;
      
      span {
        color: #108cee;
      }
    }

    .el-table__cell {

      &:hover {
        background-color: #D6D6D6!important;
      }

      // 批量选择框再大一些！20220510
      .el-checkbox {
        .el-checkbox__inner {
          width: 20px;
          height: 20px;

          // 勾选全部选项框
          &::before {
            height: 4px;
            top: 7px;
          }

          // 勾选
          &::after{
            left: 7px;
            height: 10px;
            width: 4px;
          }
        }
      }
    }
  }
</style>