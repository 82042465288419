import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const baseUrl = process.env.NODE_ENV === 'development' ? `http:${process.env.VUE_APP_BASEURL_DEV}` : `https:${process.env.VUE_APP_BASEURL_PRO}`

export default new Vuex.Store({
  state: {
    uploadUrl: `${baseUrl}/galileo/upload`,
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
