// axios 封装
import axios from "axios";
import qs from "qs";
import utils from "../libs/utils";
import { Message } from "element-ui";
import router from "../router";

const baseURL =
  process.env.NODE_ENV === "development"
    ? process.env.VUE_APP_BASEURL_DEV
    : process.env.VUE_APP_BASEURL_PRO;
// const baseURL = "https://gapi.baibo360.com"
// const baseURL = "http://192.168.1.195:11011"
// const baseURL = "http://192.168.1.185:11011"
// const baseURL = "http://192.168.1.119:11011"
axios.defaults.timeout = 1000 * 60 * 20;
axios.defaults.baseURL = baseURL;
axios.interceptors.request.use(
  (config) => {
    // console.log(config)
    let token = utils.sessionGetStore("token");
    if (token) {
      // token
      if (config.method == "post") {
        if (
          config.url.indexOf("import_low") < 0 &&
          config.url.indexOf("/galileo/import_white") < 0 &&
          config.url.indexOf("/galileo/import_list") < 0 &&
          config.url.indexOf("/galileo/importDataList") < 0 &&
          config.url.indexOf("/galileo/import_Complaint_listVO") < 0 &&
          config.url.indexOf("/galileo/import_Irrelevant") < 0 &&
          config.url.indexOf("/galileo/ImportGoods") < 0 &&
          config.url.indexOf("updateLinkState") < 0 &&
          config.url.indexOf("select_goods_info_low") < 0 &&
          config.url.indexOf("update_goods_info_data") < 0 &&
          config.url.indexOf("is_update") < 0 &&
          config.url.indexOf("selectNotUpdateGoodsCount") < 0 &&
          config.url.indexOf("deletelist") < 0 &&
          config.url.indexOf("outlist") < 0 &&
          config.url.indexOf("UpdateBrandOwner") < 0 &&
          config.url.indexOf("pauseUpdateGoodsList") < 0
        ) {
          // 序列化
          config.data = qs.stringify(config.data);
          config.headers["Content-Type"] = "application/x-www-form-urlencoded";
        }
      } else if (config.method == "put") {
        config.headers["Content-Type"] = "application/json";
      }
      config.headers["Token"] = token;
    } else {
      // token 验证失败
      if (config.url.indexOf("login") !== -1) {
        // 目标路由时登录，不做处理
      } else {
        router.push({
          path: "/login",
        });
      }
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// response 拦截器
axios.interceptors.response.use(
  (response) => {
    //成功请求到数据
    // console.log("拦截器", response);
    if (response.data.policy !== undefined) {
      return response;
    } else if (response.data.code == 999) {
      Message({
        showClose: true,
        message: response.data.msg,
        type: "warning",
      });
    } else if (response.data.code == 0 || response.status === 200) {
      return response.data;
    } else if (response.data.code == 403) {
      // utils.notificationInfo('提示','登录信息已过期，请重新登录！','warning')
      Message({
        showClose: true,
        message: "登录信息已过期，请重新登录！",
        type: "warning",
      });
      router.push({
        path: "/login",
      });
    } else {
      return Promise.reject(response.data);
    }
  },
  (error) => {
    //响应错误处理
    // console.log(error);
    return Promise.reject(error);
  }
);

export default axios;
