// 轻量级表格组件，没有分页效果

<template>
  <div class="tw_comp_style">
      <el-table
        :data="dataList"
        border
        :max-height="tableHeight"
        v-loading="loading"
      >
        <!-- 序号 -->
        <el-table-column
          v-if="orderNumConfig"
          :width="orderNumConfig.width ? orderNumConfig.width : '80'"
          :align="orderNumConfig.align ? orderNumConfig.align : 'center'"
          label="序号"
          :fixed="orderNumConfig.fixed ? orderNumConfig.fixed : false"
        >
          <template slot-scope="scope">
            <!-- 不带分页 -->
            <div>{{scope.$index + 1}}</div>
          </template>
        </el-table-column>
        <!-- 通用及渲染类 -->
        <template v-for="(item, index) in columnsList">
          <!-- 无插槽的列 -->
          <el-table-column
            v-if="!item.slotName"
            :key="index"
            :width="item.width ? item.width : ''"
            :min-width="item.minWidth ? item.minWidth : ''"
            :align="item.align ? item.align : 'center'"
            :label="item.label"
            :prop="item.prop"
            :show-overflow-tooltip="item.tooltip"
          >
            <template slot-scope="scope">
              <span>{{item.formatter ? item.formatter(scope.row[item.prop], scope.row, item, scope.$index) : scope.row[item.prop]}}</span>
            </template>
          </el-table-column>
          <!-- 有插槽的列 -->
          <slot v-else :name="item.slotName" />
        </template>
      </el-table>
  </div>
</template>

<script>
// 引入api
import api from '@/api/index'
export default {
props:{
    // 序号配置项
    orderNumConfig: {
        type: Object,
    },
    // 列的数据
    columnsList: {
        type: Array,
    },
    // 表格数据
    dataList: {
        type: Array,
        default: () => {
          return []
        }
    },
    // 获取数据配置项
    getDataConfig: {
        type: Object
    },
    // 主动更新数据标记
    updateDataTag: {
        type: Boolean
    },
    tableHeight: {
      type: Number,
      default: 500,
    },
},
data() {
    return {
      loading: false
    }
},
watch: {
    // 主动更新数据标记
    updateDataTag: {
        handler () {
            // 调用获取数据方法
            this.getDataCall()
        },
        immediate: true
    },
},
methods:{
// 调用获取数据方法
getDataCall () {
    // console.log(this.getDataConfig)
    if(!this.getDataConfig) return
    let getDataConfigObj = this.getDataConfig //通过组件传值传来的查询条件对象
    let that = this

    // 处理参数
    let paramObj = getDataConfigObj.paramObj
    // console.log(this.totalCount)
    this.loading = true
    // 请求后台api
    api[getDataConfigObj.apiName](paramObj)
    .then(function (response) {
        that.loading = false
        // 成功回调
        getDataConfigObj.success(response, function () {
        })
    })
    .catch(error => {
        // 失败回调
        getDataConfigObj.failed(error, function () {
            console.log(error)
            that.loading = false
        })
        // getDataConfigObj.failed(error)
    })
},
},

}
</script>

<style scoped lang="scss">
.tw_comp_style {
  padding: 20px 30px;
}
</style>